import React from "react";
import { graphql, Link } from "gatsby";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3';
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Project from "../components/project";
import SEO from "../components/seo";

export const query = graphql`
  query ProjectTemplateQuery($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      id
      title
      projectText {
        _rawText
      }
      images {
        _key
        asset {
          _id
        }
        crop {
          _key
          _type
          bottom
          left
          right
          top
        }
        hotspot {
          _key
          _type
          height
          width
          x
          y
        }
      }
      slug {
        current
      }
      videoUrl
      client {
        title
        url
      }
      directors {
        title
        url
      }
      producers {
        title
        url
      }
      productionCompany {
        title
      }
    }
  }
`;

const ProjectTemplate = props => {
  const { data, errors, location } = props;
  const drawerIsOpen = location?.state?.drawerIsOpen;
  const project = data && data.project;

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => {
        if (modal) {
          return (
            <>
              <Project {...project} openDrawer={drawerIsOpen} />
              <Link to={closeTo} className="back-button">
                <div>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </>
          );
        } else {
          return (
            <>
              {errors && <SEO title="GraphQL Error" />}
              {project && <SEO title={project.title || "Untitled"} />}

              {errors && (
                <Container>
                  <GraphQLErrorList errors={errors} />
                </Container>
              )}
              {project && <Project {...project} openDrawer={drawerIsOpen} />}
            </>
          );
        }
      }}
    </ModalRoutingContext.Consumer>
  );
};

export default ProjectTemplate;
