import React from "react";
import Slider from "react-slick";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import * as styles from "./carousel.module.css";

const Carousel = ({ images }) => {
  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true
  };

  return (
    <Slider {...settings} className={styles.root}>
      {images.map((image, index) => <img
          key={index}
          src={imageUrlFor(buildImageObj(image))
            .height(800)
            .url()}
          alt={image.alt ? image.alt : ''}
        />
      )}
    </Slider>
  );
};

export default Carousel;