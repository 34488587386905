// extracted by mini-css-extract-plugin
export const root = "project-module--root--1DtzU";
export const drawerIsOpen = "project-module--drawerIsOpen--3smrT";
export const container = "project-module--container--vh56w";
export const grid = "project-module--grid--tznxb";
export const video = "project-module--video--2xetj";
export const title = "project-module--title--2ReKs";
export const meta = "project-module--meta--289dU";
export const details = "project-module--details--WzHaG";
export const bar = "project-module--bar--Y3hfR";
export const toggle = "project-module--toggle--xqDIe";
export const drawer = "project-module--drawer--8aE-7";
export const credits = "project-module--credits--3AywY";