import React, { useRef, useState, useContext, useEffect } from "react";
import { Link } from "gatsby";
import { cn } from "../lib/helpers";
import StoreContext from "../context/StoreContext";
import BlockText from "./block-text";
import Carousel from "./carousel";
import LightSwitch from "./lightswitch";
import VideoPlayer from "./video-player";

import * as styles from "./project.module.css";

function scrollTo(element, to, duration) {
  var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;
      
  var animateScroll = function(){        
      currentTime += increment;
      var val = Math.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if(currentTime < duration) {
          setTimeout(animateScroll, increment);
      }
  };
  animateScroll();
}

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
t /= d/2;
if (t < 1) return c/2*t*t + b;
t--;
return -c/2 * (t*(t-2) - 1) + b;
};

function Project({ 
  title,
  videoUrl,
  client,
  directors,
  producers,
  productionCompany,
  projectText,
  images,
  openDrawer
}) {
  const drawerEl = useRef(null);
  const containerEl = useRef(null);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const store = useContext(StoreContext);

  if (images.length <= 0) {
    images = null;
  }

  useEffect(() => {
    store.closeMenu();
    store.pauseHeroVideo();

    if (openDrawer) {
      handleShowCredits();
    }

    return function() {
      store.playHeroVideo();
    }
  }, []);

  const handleShowCredits = () => {
    const drawerElHeight = drawerEl.current.offsetHeight;
    setDrawerIsOpen(true);
    scrollTo(containerEl.current, drawerElHeight, 400);
  }

  const handleHideCredits = () => {
    setDrawerIsOpen(false);
    scrollTo(containerEl.current, 0, 400);
  }

  return (
    <>
      <div className={cn(styles.root, drawerIsOpen ? styles.drawerIsOpen : '')} ref={containerEl}>
        <div className={styles.container}>
          {videoUrl && 
            <div className={styles.grid}>
              <div className={styles.video}>
                <VideoPlayer src={videoUrl} />
              </div>
              <div className={styles.details}>
                <div className={styles.bar}>
                  <div>
                    <div className={styles.title}>
                      <b>{client.title}</b>
                      <em>{title}</em>
                    </div>
                    <div className={styles.meta}>
                      <div>
                        <span>Director</span>
                        <em>{directors.map((director, index) => index < directors.length && directors.length > 1 ? `${director.title.trim()}, ` : director.title.trim())}</em>
                      </div>
                      <div>
                        <span>Production Company</span>
                        <em>{productionCompany.title}</em>
                      </div>
                      <div>
                        <span>Producer</span>
                        <em>{producers.map((producer, index) => index < producers.length && producers.length > 1 ? `${producer.title.trim()}, ` : producer.title.trim())}</em>
                      </div>
                    </div>
                  </div>
                  <div className={styles.toggle}>
                    <b>FULL STORY</b>
                    <LightSwitch isActive={drawerIsOpen} on={handleShowCredits} off={handleHideCredits} />
                  </div>
                </div>
              </div>
            </div>
          }
          <div className={styles.drawer} ref={drawerEl}>
            <div className={styles.credits}>
              {projectText && projectText.map((column, index) => <div key={index}><BlockText blocks={column._rawText} /></div>)}
            </div>
            {images && <Carousel images={images} />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Project;
