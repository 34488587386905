import React from "react";
import videojs from "video.js";

import * as styles from "./video-player.module.css";

class VideoPlayer extends React.Component {
  componentDidMount() {
    const { src } = this.props
    const videoJsOptions = {
      autoplay: false,
      controls: true,
      preload: "auto",
      responsive: true,
      fluid: true,
      liveui: true,
      bigPlayButton: false,
      sources: [
        {
          src: src,
          type: "video/mp4",
        },
      ],
      controlBar: {
        children: [
          'playToggle',
          'progressControl',
          'volumePanel'
        ]
      }
    }

    this.player = videojs(this.videoNode, videoJsOptions)
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  render() {
    return (
      <section className={styles.root}>
        <div data-vjs-player className="vjs-has-started">
          <video ref={node => (this.videoNode = node)} className="video-js"></video>
        </div>
      </section>
    )
  }
}

export default VideoPlayer
